import React, { useState, useEffect } from 'react';
import Header from './header';

export default function TransactionHistory() {
  const [email, setEmail] = useState('');
  const [history, setHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Add the isLoading state

  const retrieveUserSession = async () => {
    try {
      const session = await localStorage.getItem('user_session');
      if (session !== null) {
        const sessionItems = JSON.parse(session);
        setEmail(sessionItems.email);
      } else {
        alert('You are not logged in');
      }
    } catch (error) {
      console.error('Error retrieving user session:', error);
    }
  };

  const retrieveTransaction = async () => {
    try {
      setIsLoading(true);
      const response = await fetch('https://www.haykaytelecoms.com.ng/api/Transactionhistory', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
        }),
      });
      const data = await response.json();
      if (data.history) {
        setHistory(data.history);
      } else {
        alert('No History found');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      alert('Failed to fetch transaction history');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    retrieveUserSession().then(() => {
      retrieveTransaction();
    });
  }, [email]);

  return (
    <div>
      <Header/>
      {isLoading ? (
        <p style={{textAlign:"center",marginTop:"400px"}}>..Loading!</p>
      ) : (
        history.map((transaction, index) => (
          <div key={index} style={{}}>
            <p style={{textAlign:"center", color: 'purple', alignSelf: 'center' }}>
              {transaction.transactionlog}
            </p>
            <p style={{textAlign:"center", color: 'purple' }}>
              Date: {transaction.created_at}
            </p>
            <p style={{textAlign:"center", color: 'purple' }}>
              Transaction-Id: {transaction.reciept_id}
            </p>
          </div>
        ))
      )}
    </div>
  );
}