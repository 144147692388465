// SplashScreen.js
import React from 'react';
import './styles.css'; 
import Logo from './Assets/logo.png';
import { useEffect } from 'react';
import {useNavigate} from "react-router-dom";
export default function SplashScreen (){
  const navigate =useNavigate();
  useEffect(() =>{setTimeout(()=>{navigate('/Home');}, 6000);});
return(
  <div style={{
    color:"white",
    backgroundColor: "purple",
    
}}>

  <div className="container">
      <div className="centered">
       <img src={Logo} style={{width: '70px', height: '70px'}}/>
  <p><h1>HAYKAY</h1></p>
  <p>..We are Reliable!</p>

      </div>
    </div>
    </div>

);
}

