import { Link } from "react-router-dom";
import Header from "./header";
export default function Docs(){
return(   <div style={{marginTop:"-10px"}}>
    <Header/>
<div style={{justifyContent:"center",alignItems:"center",display:"flex",flexDirection:"column"}}>
<p className="p-2" style={{float:"right"}}> <button ><Link to="/Docs" style={{textDecoration:"none"}}>API prices</Link></button> </p>
 
<p>API Integration Documentation for Developers</p>
<p>Our  API is very easy to integrate,Note Authenticate all calls using your secret keys found on the new dashboard after succesful payment and upgrade to reseller.
</p><p>
Purchaase Airtime Endpoint URL: https://haykaytelecoms.com.ng/api/v1/vtu
    </p>
   
<code style={{marginLeft:"150px"}}> <p>Request Method:POST</p><p>
Parameters</p>
<p>Field Name	Example Value	Description</p>
<p>content-type Application/json</p>
<p>api_key	8680d4f899043527672f980705	Unique API Token On the Reseller Dashboard</p>
<p>phone	07045461790	The phone number receiving the data (eleven characters).amount</p>


<p>Sample Response on Successful purchase</p>
<p>"Status":"success","message":"Airtime successfully bought","data":"network_id":"mtn","phone":"08056708975","amount":236</p>
<p>Sample Response on Failure</p>
<p>"Status":"failure","message":"Insufficient Wallet Balance"</p>

<p>Purchaase Data Endpoint URL: https://haykaytelecom.com.ng/api/v1/buydata
</p>
</code><code style={{marginLeft:"150px"}}>

<p>Request Method:POST</p>
<p>Parameters</p>
<p>Field Name	Example Value	Description</p>

<p>api_key	     9d4f89904352767e2f980705	Unique API key On the Reseller Dashboard

</p>
<p>content-type application/json</p> 
<p>phone	The phone number receiving the data (eleven characters).
</p>

<p>network_id,
</p>

<p>dataplan_id,
</p>


<p>Sample Response on Success purchase
</p></code>
<code style={{marginLeft:"150px"}}>
<p>"Status":"success","message":"Data successfully bought","data":"network""mtn","dataplan_id":"125","phone":"08056708975","order_id":236

</p>Sample Response on Failure

<p>"Status":"failure","message":"Insufficient Balance"
</p>




<p>Purchaase cabletv Endpoint URL: https://haykaytelecoms.com.ng/api/v1/cabletv

</p>

<p>Request Method:POST</p>
</code>
<p>Parameters

</p>
<p>Field Name	Example_Value	                      Description

</p>
<p>api_key	    8d4f89904352767e2f980705	         Unique API Token On the Reseller Dashboard

</p>
<p>phone	          07045461790	                The phone number receiving the data (eleven characters).</p>
<p>network_id,          1                            The network_id ofthe recieving phonenumber as shown on the data_plan documentation page</p>

<p>dataplan_id,         242                          The data_plan id as shown on the data_plan documentation page
</p>
</div>
</div>);
}
