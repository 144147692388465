import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Header from './header';
import Logo from './Assets/logo.png';
import Paystack from './Assets/paystack.png';
import { usePaystackPayment } from 'react-paystack';
import { PaystackButton } from 'react-paystack';

const Fundapi  = () => {
    const publicKey = "pk_live_0890fce70ac1ed82aee63188096b3303549f6f0b";
    const [ amountpayin,setAmount]=useState("") ; 
    const [email, setEmail] = useState("");
    const [name, setName] = useState("User");
    const amount= Number(amountpayin*100);
   // const amount = amountPayIn ? Number(amountPayIn) * 100 : 0;

    //const amount = Number(amountPayIn) * 100;
    const componentProps = {
      email,
     amount,
      metadata: {
        name,
        phone: "08123456789",
      },
      publicKey,
      text: "Pay Now",
      onSuccess: (response) => {
        console.log(response);
        alert("Payment Successful!");
      },
      onClose: () => alert("Payment closed"),
    };
  
    return (
      <div>
      <div style={{marginLeft:'300px',justifyContent:'center',color:'purple',flexDirection:'column'}}>
      <h2>Payment Page</h2>
      <p><label>Enter Email Address used on Haykay(Without Error)</label><input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter your email e.g Akintunji@gmail.com"
      /></p><p><label>Enter Amount </label>
      <input
        type="text"
        value={amountpayin}
        onChange={(e) => setAmount(e.target.value)}
        placeholder="Enter Amount e.g 400"
      /></p>
    <p>  <PaystackButton {...componentProps} /></p>
    <div>      <img src={Paystack}/>
</div>      </div></div>      
 
    );
  };
  
  export default Fundapi;