import { Form } from "react-bootstrap";
import { useState } from "react";
import Header from "./header";
export default function Forgotpassword(){
///dunction(){

    const [email,setEmail]=useState();
    const handleSubmit = (e) => {
        e.preventDefault();
     //   SetLogging(true);
        fetch('https://www.haykaytelecoms.com.ng/api/resetpassword', {
          method: 'POST',
          headers: {
           // 'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ email,}),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.status =="Success") {
       //     }
        alert('An email has been sent to your email Address!')  
     } });     
    }
    const hanfdlesubmit=()=>{

    



}
return(

<div style={{color:"purple"}}>
<Header/>
<p>Kindly Provide the email!</p>
<Form onSubmit={handleSubmit}>
<p><label>Enter Email Address</label>

<Form.Control
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
</p>

<p><input type="Submit" value='Submit' /></p>
</Form>




</div>
);
}