import React, { useState, useEffect } from 'react';
import './fade.css';
import Dstv from './Assets/DStv.jpg';
import  Image2 from './Assets/Data.png';

const FadeComponent = ({ src }) => {
  const [fade, setFade] = useState(true);
//const [src, setsorce]=useState([Dstv,Image2]);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(prev => !prev);
    }, 2000); // Change every 2 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <img
      src={src}
      className={`fade-image ${fade ? 'fade-in' : 'fade-out'}`}
      alt="Fading"
      style={{width:'270px'}}
    />
  );
};

export default FadeComponent;