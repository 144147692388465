import Forgotpassword from './Forgotpassword';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
//import Login from './Login';
import Register from './Register';
//import TransactionHistory from './TransactionHistory.js';
import TransactionHistory from './TransactionHistory.js';
import About from './About';
import Splashscreen from './Splashscreen' ; 
import Welcome from './Welcome';
import Dashboard from './Dashboard';
import Login from './Login';
import Docs from './docs';
import Data from './Data';
import Vtu from './Vtu';
import Cable from './Cable';
import Fund from './Fund';
import Paystackpage from './Paystackpage';
import Fundapi from './Fundapi.js';
import ResellerDashboard from  './resellerdashboard';
import Apidashboard from './Apidashboard';
import Electricity from './Electricity';

export default function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Splashscreen />} />
          <Route path="/Home" element={<Welcome/>} />
           <Route path="/Login" element={<Login/>} />
          <Route path="/Register" element={<Register/>} />
           <Route path="/Dashboard" element={<Dashboard/>} />
           <Route path="/ResellerDashboard" element={<ResellerDashboard/>} />
          <Route path="/Apidashboard " element={<Apidashboard />} />
          <Route path="/ResellerBuydata" element={<Data/>} />
          <Route path="/Buydata" element={<Data/>} />
       <Route path="/Paystackpage" element={<Paystackpage/>}/>
          <Route path="/Docs" element={<Docs/>}/>
          <Route path="/Buydata" element={<Data/>} />
          <Route path="/Buyelectricity" element={<Electricity/>} />
          <Route path="/Buyvtu" element={<Vtu/>} />
         <Route path="/Buycable" element={<Cable/>} />
         <Route path="/TransactionHistory" element={<TransactionHistory/>}/>
         <Route path="/Fund" element={<Fund/>}/>
         <Route path="/fundpage" element={<Fundapi/>}/>
           <Route path='Forgotpassword' element={<Forgotpassword/>}/>
           </Routes>
      </BrowserRouter>
  );
}
