import React, { useState } from 'react';
import Header from './header';
import Logo from  "./Assets/logo.png";
 export default function Vtu () {
  const [networkId, setNetworkId] = useState('');
  const [amount, setAmount] = useState('');
  const [phonenumber, setPhonenumber] = useState('');
  const [errors, setErrors] = useState([]);
const [email, setEmail]=useState('');
const [Loading,setloading]=useState('');
  const handleSubmit = (event) => {
    event.preventDefault();
    
  };


  const Buy =(event) => {
    event.preventDefault();
   setloading(true);
    fetch('https://haykaytelecoms.com.ng/api/buy_vtu', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email:email, 
      Phonenumber:phonenumber,
      amount: amount,
      network_id:networkId,
       
    })
    }).then((response) => response.json())
      .then((response) => {
     if (response.status=="success"){
        alert('Transaction Successful');
     
      }
       else{
        alert(response.mssg);
        setloading(false);
      
      }
      
       
      });
      
  
    }



  return (
    <div style={{color:"white",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    marginInlineStart:"700",
    backgroundColor: "purple",
  }}><Header/>
              
              {!Loading?  
            <form onSubmit={Buy}>
             
             <p><label className="ml-4" style={{  marginTop: 20 }}>
                SELECT NETWORK
              </label>
              <select
                name="network_id"
                value={networkId}
                onChange={(event) => setNetworkId(event.target.value)}
              >
                <option value="1">Mtn</option>
                <option value="3">Glo</option>
                <option value="2">Airtel</option>
                <option value="4">9mobile</option>
              </select></p> 
              <p className="ml-12">
                <label
                  className="ml-4"
                  style={{ marginTop: 20 }}
                >
                  Enter Amount
                </label>
                <input
                  type="text"
                  name="amount"
                  value={amount}
                  onChange={(event) => setAmount(event.target.value)}
                  placeholder="e.g #200"
                />
              </p>
              <label
                className="ml-4"
                style={{ marginTop: 20 }}
              >
                Phone Number
              </label>
              <input
                type="text"
                name="phonenumber"
                value={phonenumber}
                onChange={(event) => setPhonenumber(event.target.value)}
                placeholder="088900"
              />
              <p className="ml-4" style={{ marginTop: 20 }}>
                <input type="submit" value="Buy" />
              </p>
            </form>
      
    :<div style={{
      display: 'flex',
      flexDirection:'column',
      justifyContent: 'center',
      alignItems: 'center',
    
     }}>   
     
   <div> <img src={Logo} style={{ width: '160px', height: '150px' }} /></div>
        <p style={{alignItems:'center',color:'pink'}}>Loading,Exercise Patience!</p>

      </div>
      
        
}

      <p className="ml-12 text-rose-500" style={{ color: 'purple' }}>
        Haykay 2022
      </p>


    </div>
  );
}

