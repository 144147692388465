import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import React, { useState } from 'react';
import Header from './header';
function Register() {
  // State for form fields
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    Referalcode:'',
    phonenumber:'',
  });

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    
    try {
      const response = await fetch('rentstack.com.ng/api/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // Registration successful
        const data = await response.json();
        console.log('Registration successful:', data);
        // Optionally, you can perform actions like redirecting the user to a login page
      } else {
        // Registration failed
        const errorData = await response.json();
        console.error('Registration failed:', errorData);
        // Optionally, you can display error messages to the user
      }
    } catch (error) {
      console.error('An error occurred during registration:', error);
    }
  };

  return (

    <div style={{height:'2700px',color:'white',background: 'linear-gradient(to right,purple,#ffffff,purple)', marginTop:'-20px',}}>
      <Header/>
      <div style={{marginLeft:'200px'}}> 
      <Form onSubmit={handleSubmit}>
        <p style={{marginBottom:'20px'}}><Form.Group controlId="firstName">
         <Form.Label>First Name:</Form.Label>
           <Form.Control
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
        </Form.Group>
        </p>
        <p style={{marginBottom:'20px'}}>        <Form.Group controlId="lastName">
          <Form.Label>Last Name:</Form.Label>
          <Form.Control
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
          />
        </Form.Group>
</p>
<p style={{marginBottom:'20px'}}>    <Form.Group controlId="email">
          <Form.Label>Email:</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </Form.Group>
</p>
<p style={{marginBottom:'20px'}}>    <Form.Group controlId="password">
          <Form.Label>Password:</Form.Label>
          <Form.Control
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </Form.Group>
</p>
<p style={{marginBottom:'20px'}}>    <Form.Group controlId="confirmPassword">
          <Form.Label>Confirm Password:</Form.Label>
          <Form.Control
            type="password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
          />
        </Form.Group>
</p>

<div style={{marginBottom:'20px',marginTop:"20px"}}>    <Form.Group controlId="Phonenumber">
          <Form.Label>Phone Number:</Form.Label>
          <Form.Control
            type="text"
            name="phonenumber"
            value={formData.phonenumber}
            onChange={handleChange}
            required
          />
        </Form.Group>
        </div>
        <div style={{marginBottom:'20px',marginTop:"20px"}}>    <Form.Group controlId="confirmPassword">
          <Form.Label>Referal Code(optional)</Form.Label>
          <Form.Control
            type="text"
            name="Referalcode"
            value={formData.Referalcode}
            onChange={handleChange}
            required
          />
        </Form.Group>
</div>
        <Button variant="primary" type="submit">
          Register
        </Button>
      </Form>
      </div>
    </div>
  );
}

export default Register;
