import { Link,useNavigate } from 'react-router-dom';
import Header from './header';
import { useState,useEffect } from 'react';
function Dashboard() {
  const [name, SetName]=useState('Akin');
  const [balance, setBalance]=useState('');
  const [email, setEmail]=useState('');
  const [Isloggedin, setIsLogged] = useState(false);
  const navigate = useNavigate();

  const retrieveUserSession = async () => {
    try {
      const session = await localStorage.getItem("user_session");
      if (session !== null) {
        const session_items = JSON.parse(session);
        setEmail(session_items.email);
        SetName(session_items.name);
        setBalance(session_items.balance);
        
        setIsLogged(true);
      } else {
        setIsLogged(false);
      }
    } catch (error) {
      console.error("Error retrieving user session:", error);
    }
  };


  const logincheck = () => {
     if(Isloggedin=="false") {
      navigate('/Welcome');
    }
  }

  useEffect(() => {
  logincheck();
retrieveUserSession();
  }, []);
 

  return (
  <div style={{color:'white',
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  marginInlineStart:"700",
  backgroundColor: "purple",
}}><Header/>
<p></p>
<p>Welcome {name} </p>
<div style={{display:"flex",flexDirection:"row",}}>
<p><button style={{color:"gold"}}><Link to="/Fund" style={{textDecoration:"none"}}> Fund Wallet</Link></button></p>
<p><button style={{backgroundColor:"white",color:""}}><Link to="/Fund" style={{textDecoration:"none"}}>Reseller upgrade</Link></button></p>
</div>

<div style={{color:"white",marginLeft:"20px",}}>
<p><Link to="/Buyvtu" style={{ textDecoration: 'none',color:"white"}}>Buy Vtu</Link></p>
<p> <Link to="/Buydata"  style={{ textDecoration: 'none',color:"white"}}> Buy Data </Link></p>
<p> <Link to="/Buycable"  style={{ textDecoration: 'none',color:"white"}}>Buy cabletv </Link> </p>
<p> <Link to="/Buyelectricity"  style={{ textDecoration: 'none',color:"white"}}>Buy Electricity</Link></p>
<p> <Link to="/TransactionHistory"  style={{ textDecoration: 'none',color:"white"}}>TransactionHistory</Link></p>
</div>
</div>
  );
}

export default Dashboard;