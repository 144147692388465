import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './header';
function Cable() {
  const [decoder_type, setDecodertype] = useState();
  const [Plan_id,setPlan] = useState('');
  const [Decodernumber, setDecodernumber] = useState('');
  const [amount, setAmount] = useState('');
  const [phonenumber, setPhonenumber] = useState('');
  const [errors, setErrors] = useState([]);
 const [email, setemail]=useState('');
 const [iuc, Set_iuc]=useState('');
const [Loading, setLoading]=useState('');
 const handleSubmit = (event) => {
    event.preventDefault();
    // Add your form submission logic here
  };

  const navigate=useNavigate();

  async function retrieveUserSession() {
    try {   
const session=await localStorage.getItem("user_session");
  if (session!==undefined) {
        const session_items=JSON.parse(session);
    setemail(session_items.email);

//    setbalance(session_items.balance);
  
        }
        else{alert('You are not Log In')}
    } catch (error) {
        
    }
  }

  
const Buy =async () => {
setLoading(true);
   fetch('https://www.haykaytelecoms.com.ng/api/buy_cable', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({email:email,
      decoder_type:decoder_type,
      plan_id:Plan_id,
      iuc:iuc,

      
    })
  }).then((response) => response.json())
    .then((response) => {
      setLoading(false);
      //const errorMessage = response.text();
       if (response.status == "Success") {
        navigate('dashboard')
      }
      else {
        alert('Failed Transaction!')
        setLoading(false);
      }

    });
  }

  
  
  return (
  <div style={{color:"white",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  marginInlineStart:"700",
  backgroundColor: "purple",
}}>
    <Header/>
            {errors.length > 0 && (
              <div className="alert alert-danger text-red-600 ml-12px">
                <ul>
                  {errors.map((error) => (
                    <li key={error}>{error}</li>
                  ))}
                </ul>
              </div>
            )}
            <div style={{ marginTop: 20,marginLeft:"30px",marginTop:"30px" }}>
            <form onSubmit={Buy} >
             <p style={{color:"white",
  justifyContent: "center",
  alignItems: "center",}}><label className="ml-12">Select Decoder Type </label>
              <select
                name="decoder_type"
                value={decoder_type}
                onChange={(event) => setDecodertype(event.target.value)}
              >
                <option value="3">Startimes</option>
                <option value="1">GOTV</option>
                <option value="2">DSTV</option>
                 </select>
              </p>
              <p style={{color:"white",
  justifyContent: "center",
  alignItems: "center",}}>
     <label>Select Plan</label>
               
              <select
                name="Planid"
                value={Plan_id}
                onChange={(event) => setPlan(event.target.value)}
              ><option value="13" >STARTIMES Nova	N1194  (30 days) </option>
<option value="14" >STAR TIMES Basic - #2095  - 1 Month</option>
<option value="15" >STARTIMES Smart - #2,790  - 1 Month -</option>
<option value="16" >STARTIME	Classic - 	N3085 - 1 Month</option>
<option value="17" >STARTIME	Super - N5285 - 1 Month</option>
<option value="1" > GOTV smallie monthly=#1100 </option>
<option value="2" > GOTV jinja=#2253 </option>
<option value="3" >GOTV jolli N3298</option>
<option value="4" >Gotv Max=N4834</option>
<option value="5" > Gotv supa=N6386</option>
<option value="18" > Gotv smallie=#2890 QUARTERLY</option>
<option value="6">Dstv Padi N2500</option>
<option value="7">DStv Yanga - ₦3495 </option>
<option value="28" > DStv Padi + ExtraView - ₦5080</option>
<option value="8" > Dstv Confam (30 days)	N6170	</option>
<option value="9" >Dstv Compact (30 days)N10450</option>
<option value="10"> Dstv Compact Plus (30 days)	N16510	</option>
<option value="11">	DStv Premium - ₦24380	</option>
<option value="12">	Dstv Premiun Asia (30 days)	N32450	</option>
                  </select>
              </p >
              <p
              style={{color:"white",
              justifyContent: "center",
              alignItems: "center",}}>
                <label>Meter number</label>
                <input
                  type="text"
                  name="meternumber"
                  placeholder="Enter decoder number"
                  value={Decodernumber}
                  onChange={(event) =>  setDecodernumber(event.target.value)}
                />
              </p>
              <p style={{color:"white",
  justifyContent: "center",
  alignItems: "center",}}>
                <label>Amount</label>
                <input
                  type="text"
                  name="amount"
                  placeholder="amount"
                  value={amount}
                  onChange={(event) => setAmount(event.target.value)}
                />
              </p>
              <p style={{color:"white",
  justifyContent: "center",
  alignItems: "center",}}>
                <label>Customer phonenumber</label>
                <input
                  type="text"
                  name="Phonenumber"
                  placeholder="Enter phonenumber"
                  value={phonenumber}
                  onChange={(event) => setPhonenumber(event.target.value)}
                />
              </p>
              <p className="ml-12">
                <input type="submit" name="submit" value="submit" />
              </p>
            </form>
          </div>
          </div>
  );
}

export default Cable;

