import { useState,useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Header from './header';
//import Paystackpage from './Paystackpage';
import Logo from './Assets/logo.png';
import Paystack from './Assets/paystack.png';
import { usePaystackPayment } from 'react-paystack';
import { PaystackButton } from 'react-paystack';
 

import { useNavigate } from 'react-router-dom';
import Paystackpage from './Paystackpage';
export default function Fund() {
 const publicKey = "pk_live_0890fce70ac1ed82aee63188096b3303549f6f0b";
    const [ amountpayin,setAmount]=useState("") ; 
    const [email, setEmail] = useState("");
    const [name, setName] = useState("User");
    const amount= Number(amountpayin*100);
 const [reference,Setrefernece]=useState('');
    const [Isloggedin, setIsLogged]=useState('');
 
    const paymentconfirmed=(reference)=>{
 fetch('https://www.haykaytelecoms.com.ng/api/paymentsent', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },

    body: JSON.stringify({
         email: email,
      reference:reference,
       
    })
});


 }

 
 const retrieveUserSession = async () => {
  try {
    const session = await localStorage.getItem("user_session");
    if (session !== null) {
      const session_items = JSON.parse(session);
      setEmail(session_items.email);
         
      } else {
      setIsLogged(false);
    }
  } catch (error) {
    console.error("Error retrieving user session:", error);
  }
};



 
 const componentProps = {
      email,
     amount,
      metadata: {
        name,
        phone: "08123456789",
      },
      publicKey,
      text: "Pay Now",
      onSuccess: (response) => {
        alert("Payment Successful!");
       paymentconfirmed(); 
      },
      onClose: (response) => {alert("Payment closed");

    console.log(response);
    }
    };

    useEffect(() => {
    retrieveUserSession();
      }, []);
     
      
  
return (

  <div>
    <Header/>
  <div style={{marginLeft:"30px",color:"purple"}}>
          <p>
        <label>Enter Amount To Fund</label><input
          type="text"
          value={amountpayin}
          onChange={(e) => setAmount(e.target.value)}
          placeholder="Enter Amount e.g 400"
        /></p>
        <PaystackButton {...componentProps} />
        </div>
        <img src={Paystack}/>

        <p style={{textAlign:"center",color:"purple"}}>Funding is Instant</p>

</div>
    

);

}

 